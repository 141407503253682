import { Button, Stack } from '@mui/material';

import { useStrings } from 'src/hooks/useStrings';

type Props = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  fetchPreviousPage: () => void;
  fetchNextPage: () => void;
  total?: number;
  current?: number;
};

export const Pagination: React.FC<Props> = ({
  hasNextPage,
  hasPreviousPage,
  fetchPreviousPage,
  fetchNextPage,
  current = 0,
  total = 0,
}) => {
  const { strings } = useStrings();
  return (
    <Stack direction="row" alignItems="center">
      <Button disabled={!hasPreviousPage} onClick={fetchPreviousPage}>
        {strings('prev')}
      </Button>
      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
        {current} / {total}
      </div>
      <Button disabled={!hasNextPage} onClick={fetchNextPage}>
        {strings('next')}
      </Button>
    </Stack>
  );
};
