export const product = {
  product: 'Producto',
  products: 'Productos',
  inventoryBulkDeleteWarning: 'Se eliminará todo tu inventario.',
  partialDelete1: 'Se eliminarán',
  partialDelete2: 'productos de tu inventario.',
  inventoryReport: 'Reporte de inventario',
  createNewProductTitle: 'Inventario: Crea un nuevo producto',
  createNewProductHeading: 'Crea un nuevo producto',
  productCreationSuccess: 'Producto creados con éxito',
  productsCreationSuccess: 'Productos creados con éxito',
  productFormName: 'Nombre del producto',
  productFormDescription: 'Descripción del producto',
  productFormNewCategory: 'Crear nueva categoría',
  productFormShowInStore: 'Mostrar en tienda en línea',
  productFormShowInPOS: 'Mostrar en PDV',
  productFormHasVariants: 'Producto tiene propiedades',
  productFormConfigureExtras: 'Configurar Adicionales',
  productFormPrice: 'Precio de venta',
  productFormCost: 'Costo del producto',
  productFormStock: 'Cantidad de stock',
  productFormImageUpload: 'Límite de 5 imágenes por producto',
  productFormCreate: 'Crear producto',
  productFormErrors: 'Por favor corrige los errores he inténtalo de nuevo.',
  addExtra: 'Asignar adicionales',
  confirmExtras: 'Configurar adicionales',
  productEditSuccess: 'Producto editado con éxito',
  stockValidation: 'Stock no puede ser menos del ya existente',
  variantsValidation: 'Debes especificar al menos 1 variante',
  color: 'Color',
  size: 'Tamaño / Talla',
  style: 'Estilo / Material',
  productDeleteWarning1:
    'Esta eliminación no quedará registrada, y no afectará tus ganancias.',
  productDeleteWarning2:
    'En Detalles podrás encontrar este cambio como "Producto dañado". En Finanzas, este tipo de movimientos representan un Gasto para el negocio.',
  productDeleteSuccess: 'Producto eliminado con éxito',
  productDeleteMessage:
    'Estás a punto de eliminar un producto, ¿a qué se debe?',
  productDeleteReason1: 'Por daños/averío',
  productDeleteReason2: 'Ya no deseo venderlo',
  productScanNotFoundTitle: 'Producto no encontrado',
  productScanNotFoundDescription: 'No tienes registrado este producto.',
  productScanNoStockTitle: 'Producto sin suficiente stock',
  productScanNoStockDescription:
    'No cuentas con suficiente stock para esta venta.',
  latestScan: 'Último Escaneo',
  stockUpdateSale:
    'Las ventas de productos se deben registrar por medio del módulo Punto de Ventas o módulo de Contabilidad. Para asegurar el buen uso en el futuro, hazlo por medio de esas funciones.',
  stockUpdateDamage:
    'En Detalles podrás encontrar este cambio como "Producto dañado". En Finanzas este tipo de movimientos representan un Gasto para el negocio.',
  stockUpdateError: 'No podrás deshacer esta acción, no quedará registro.',
  stockUpdateNewCost:
    'El costo del producto será un promedio entre unidades que ya tenías con sus respectivos costos, y las unidades adicionales.',
  inventoryUpdate: 'Actualización de inventario',
  inventoryUpdateMessage:
    'Estás a punto de modificar el inventario, ¿qué deseas hacer?',
  increase: 'Aumentar',
  decrease: 'Disminuir',
  howManyUnits: '¿Cuántas unidades?',
  costChange: '¿El costo de estas unidades es distinto?',
  byCreationError: 'Por error de ingreso',
  understood: 'Entiendo',
  inventoryUpdateDismiseTitle: 'Tus cambios serán descartados',
  inventoryUpdateDismiseMessage:
    'Se reestablecerán los datos que tenemos guardados de tu producto.',
  continueEditing: 'Continuar editando',
  finalStock: 'Stock final',
};
export type ProductStrings = typeof product;
