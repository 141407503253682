import { TranslationKeys } from 'src/locales/es';

import useLocales from './useLocales';

export const useStrings = () => {
  const { translate, currentLang } = useLocales();

  const strings = (key: keyof TranslationKeys) => translate(key);

  return {
    strings,
    currentLang,
  };
};
