import { GeneralStrings } from '../es/general';

export const general: GeneralStrings = {
  voiceWelcome:
    'Hi! Welcome to Cabal AI, click the button and say something like "Create a $10 expense"',
  voiceMicPermission: 'We need permission to use your microphone.',
  voiceNoMic: 'You need a microphone to use this feature.',
  voiceTimeLimit:
    'The audio you have recorded exceeds %maxSecs seconds, please try to be shorter.',
  voiceExpenseCreated: 'Expense created successfully',
  voiceProcessing: 'Processing...',
  accountUnableToLogout: 'Unable to logout!',
  accountProfile: 'Your profile',
  accountLogOut: 'Log out',
  whatsNewTitle: 'Latest updates',
  whatsNewNocontent: 'No content available',
  whatsNewError: 'Something went wrong',
  whatsNewPlublishedOn: 'Published on',
  close: 'Close',
  edit: 'Edit',
  loading: 'Loading',
  search: 'Search',
  sort: 'Sort',
  filter: 'Filter',
  by: 'by',
  name: 'Name',
  return: 'Go back',
  barcode: 'Barcode',
  none: 'None',
  priceUp: 'Price up',
  priceDown: 'Price down',
  new: 'New',
  new2: 'New',
  newer: 'Newer',
  older: 'Oldest',
  price: 'Price',
  cost: 'Cost',
  add: 'Add',
  buy: 'Buy',
  more: 'more',
  apply: 'Apply',
  next: 'Next',
  prev: 'Previous',
  youHave: 'You have',
  order: 'Order',
  employees: 'Employees',
  delete: 'Delete',
  cancel: 'Cancel',
  continue: 'Continue',
  writeConfirmation: 'To continue please write',
  selectAll: 'Select all',
  cancelSelection: 'Cancel selection',
  deleteSelection: 'Delete selection',
  bulkSelect: 'Bulk select',
  outOfStock: 'Out of stock',
  lowStock: 'Low stock',
  createNew: 'Create new',
  createNew2: 'Create new',
  requestReport: 'Request report',
  date1: 'Date',
  date2: 'Dates',
  from: 'From',
  until: 'Until',
  imageUploaderTitle: 'Select the images',
  imageUploaderDescription: 'Drag files or click here to choose images',
  imageUploaderRejection:
    'Please check the maximum number of files, and try again.',
  imageUploaderValidation: 'The maximum number of images is 5',
  required: 'Required',
  limit: 'Limit',
  image: 'image',
  images: 'images',
  max: 'max',
  currentImages: 'Existing images',
  imageDeleteConfirmation: 'Are you sure you want to delete this image?',
  deleteConfirmation: 'Please confirm to continue.',
  saveChanges: 'Save changes',
  save: 'Save',
  confirm: 'Confirm',
  important: 'Important',
  regenerate: 'Regenerate',
  regenerateWarning:
    'The services you have integrated will stop working. After regenerating the API Key, update all the integrations you have made with the new code to avoid problems.',
  apiKey: 'API Key',
  noApiKey: 'You do not have an active API Key.',
  copy: 'Copy',
  copied: 'Copied!',
};
