import moment, { Moment } from 'moment';

import { V1StripeChargesPostRequestPlanTypeEnum } from 'src/api-client';

export interface SubscriptionOption {
  title: string;
  description: string;
  benefits: string[];
  best_value: boolean;
  cost: string;
  plan_type?: V1StripeChargesPostRequestPlanTypeEnum;
  image?: string;
  notes?: string;
}

export const offersStartDate: Moment = moment('09/29/2024 00:00');
export const offersEndDate: Moment = moment('10/31/2024 23:59');

const commonBenefits = ['Cancela cuando quieras'];

export const defaultPlans: SubscriptionOption[] = [
  {
    title: 'Cabal Plus',
    description: '/mes',
    benefits: [...commonBenefits],
    plan_type: 'monthly',
    cost: 'USD$6.99',
    best_value: false,
  },
  {
    title: 'Cabal Plus',
    description: '/año',
    benefits: ['20% OFF', ...commonBenefits],
    plan_type: 'anual',
    cost: 'USD$67.00',
    best_value: true,
  },
];

export const offerPlans: SubscriptionOption[] = [
  {
    title: 'Cabal Plus',
    description: '/mes por 3 meses',
    benefits: ['57% OFF', ...commonBenefits],
    plan_type: 'month_299' as V1StripeChargesPostRequestPlanTypeEnum,
    cost: 'USD$2.99',
    best_value: true,
    notes: 'Luego de 3 meses, precio normal USD$6.99',
  },
  {
    title: 'Cabal Plus',
    description: '/año',
    benefits: ['20% OFF', ...commonBenefits],
    plan_type: 'anual',
    cost: 'USD$67.00',
    best_value: false,
  },
];

export const hasOffersEnabled = () => {
  const todaysMoment = moment();
  const enableOffers =
    todaysMoment >= offersStartDate && todaysMoment < offersEndDate;

  return enableOffers;
};
