export const userAccount = {
  general: 'General',
  cabalPlus: 'Cabal Plus',
  integrations: 'Integraciones',
  changePassword: 'Cambiar contraseña',
  yourAccount: 'Tu cuenta',
  firstName: 'Nombre',
  lastName: 'Apellido',
  country: 'País',
  phone: 'Teléfono',
  email: 'Email',
  currency: 'Moneda',
  industry: 'Rubro',
  businessName: 'Negocio',
  changeSuccess: '¡Cambios guardados!',
  oneTimeField:
    'Este campo no podrá ser editado después de guardar los cambios.',
  creatingAccount: 'Creando cuenta',
  business: 'negocios',
  personal: 'personal',
  creatingBusiness: 'de negocios',
  creatingPersonal: 'de finanzas personales',
  accountTypeLogged: 'Has iniciado sesión con',
  selectYourCountry: 'Selecciona tu país',
  employeeCount: 'Número de empleados',
  employeeCount1: 'Solo soy yo',
  employeeCount2: 'De 0 a 3',
  employeeCount3: 'De 4 a 10',
  employeeCount4: 'De 11 a 25',
  employeeCount5: 'Más de 25',
  dollar: 'Dólar',
  currencyFormat: 'Formato de moneda',
  phoneDisclaimer:
    'Usamos tu número para la creación y envío de recibos de cobro a través de WhatsApp.',
  finishRegistration: 'Finalizar registro',
};
export type UserAccountStrings = typeof userAccount;
