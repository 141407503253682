import { SubscriptionStrings } from '../es/subscription';

export const subscription: SubscriptionStrings = {
  subscription: '',
  subscriptionCancelSuccess: '',
  loadingSubscriptionInfo: '',
  loadingSubscriptionInfoError: '',
  monthly: 'Monthly',
  yearly: 'Yearly',
  status: 'Status',
  daysAvailable: 'Days available',
  dayAvailable: 'Day available',
  freeTrial: 'Free trial',
  activeSubscription: 'Active',
  canceledSubscription: 'Canceled',
  startDate: 'Start date',
  endDate: 'End date',
  nextPayment: 'Next payment',
  cancelationInfo:
    'You have cancelled your subscription, you will have access to Cabal Plus until',
  confirmCancelation: 'Confirm Cancelation',
  confirmAction: 'Confirm action',
  confirmCancelationMessage:
    'We will retain your data, but you will lose access to all Cabal Plus features.',
  cancelSubscription: 'Cancel Subscription',
  cancelExternalSubscriptionMesage:
    'To cancel your subscription, visit the store (Google Play or App Store) on your mobile device.',
  wsCTA: 'Join today',
  wsMessage:
    'Join our WhatsApp group to learn effective ways to manage your business.',
};
