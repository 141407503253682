import { Cancel, Check, Mic, Stop } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery } from '@tanstack/react-query';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { useNavigate } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

import { QueryTypes } from 'src/@types/queryTypes';
import { AxiosRailsError } from 'src/@types/railsError';
import { VoiceCommandsApi } from 'src/api-client';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useDirectUpload } from 'src/hooks/useDirectUpload';
import { useOnError } from 'src/hooks/useOnError';
import { useStrings } from 'src/hooks/useStrings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { generateMp3File } from 'src/utils/generateMp3File';

type Props = {
  isCollapse: boolean;
};

const maxSecs = 30;

const convertStoMs = (totalSeconds: number, padStart: number = 2) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = (maxSecs - totalSeconds) % 60;

  const paddedMins = minutes.toString().padStart(padStart, '0');
  const paddedSecs = seconds.toString().padStart(padStart, '0');

  return `${paddedMins}:${paddedSecs}`;
};

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  background: `linear-gradient(to bottom, ${theme.palette.secondary.lighter}, #ffffff)`,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  position: 'relative',
}));

const ActionContainer = styled('div')(() => ({
  width: 56,
  height: 56,
  position: 'relative',
  '.progress': {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    pointerEvents: 'none',
  },
  '.fab-action': {
    position: 'relative',
    zIndex: 1,
  },
}));

const Writter = ({ strings }: { strings: string }) => {
  return (
    <Typewriter
      options={{
        delay: 20,
        strings,
        autoStart: true,
        cursor: '',
      }}
    />
  );
};

interface ProvisionalTranscriptResult {
  payload: {};
  transcription: 'string';
}

export const CabalVoice: React.FC<Props> = ({ isCollapse }) => {
  const { strings } = useStrings();
  const [transcriptResultsId, setTranscriptResultsId] = useState<string | null>(
    null
  );
  const [blob, setBlob] = useState<Blob | null>(null);
  const [transcript, setTranscript] = useState<string | null>(null);
  const [showNoPermission, setShowNoPermission] = useState(false);
  const [showNoMicAvailable, setShowNoMicAvailable] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { onError } = useOnError();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { sendAnalyticsEvent } = useAnalytics();

  const {
    isFetching: isFetchingTranscriptResult,
    isSuccess: isTranscriptResultSuccess,
  } = useQuery({
    queryKey: [QueryTypes.VoiceCommands, 'get'],
    queryFn: async () => {
      const result = await VoiceCommandsApi.v1VoiceCommandsTranscribeIdGet({
        id: transcriptResultsId as string,
      });

      return result.data as unknown as ProvisionalTranscriptResult;
    },
    onSuccess: (data) => {
      setTranscript(data.transcription);
    },
    onError: (error: AxiosRailsError) => {
      onError(error);
    },
    enabled: Boolean(transcriptResultsId),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: false,
  });

  const {
    directUpload,
    reset: resetTranscriptMutation,
    isLoading: isLoadingTranscriptCreate,
    isSuccess: isTranscriptCreateSuccess,
  } = useDirectUpload({
    onSuccess: ({ id }) => {
      setTranscriptResultsId(`${id}`);
      setShowWarning(false);
    },
  });

  const {
    recordingBlob,
    isRecording,
    recordingTime,
    startRecording,
    stopRecording,
    mediaRecorder,
  } = useAudioRecorder(
    {
      sampleRate: 16000,
      noiseSuppression: true,
      echoCancellation: true,
    },
    (error) => {
      if (error.name === 'NotAllowedError') {
        setShowNoPermission(true);
      }
      if (error.name === 'NotFoundError') {
        setShowNoMicAvailable(true);
      }
    }
  );

  const timeExceeded = recordingTime > maxSecs;
  const reset = () => {
    setTranscript(null);
    setTranscriptResultsId(null);
    setBlob(null);
    resetTranscriptMutation();
    resetCreateMutation();
  };

  const start = () => {
    sendAnalyticsEvent('ai_start_recording');
    startRecording();
  };

  const stop = () => {
    stopRecording();
  };

  const cancel = () => {
    setShowWarning(false);
    reset();
  };

  useEffect(() => {
    if (timeExceeded) {
      setShowWarning(true);
      stop();
      return;
    }

    if (!recordingBlob || isRecording || showWarning) {
      return;
    }

    setBlob(recordingBlob);
  }, [recordingBlob, timeExceeded, showWarning, isRecording]);

  useEffect(() => {
    if (blob) {
      getTranscript(blob);
    }
  }, [blob]);

  const getTranscript = async (blob: Blob) => {
    const mp3File = await generateMp3File(blob);
    await directUpload(mp3File);
  };

  const {
    mutateAsync: createExpense,
    isLoading: isLoadingCreate,
    reset: resetCreateMutation,
  } = useMutation({
    mutationFn: async () => {
      const response = await VoiceCommandsApi.v1VoiceCommandsExecutePost({
        v1VoiceCommandsExecutePostRequest: {
          audio_id: transcriptResultsId as string,
          transcription: transcript as string,
        },
      });

      return response.data;
    },
    onSuccess: (data) => {
      sendAnalyticsEvent('ai_create_expense');

      const { ids } = data;
      const action = (snackbarId: SnackbarKey) => (
        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              navigate(`${PATH_DASHBOARD.details.all}?ids=${ids.join(',')}`);
              closeSnackbar(snackbarId);
            }}
          >
            Ver gasto
          </Button>
          <Button
            size="small"
            variant="text"
            onClick={() => {
              closeSnackbar(snackbarId);
            }}
          >
            Cerrar
          </Button>
        </Stack>
      );
      enqueueSnackbar(strings('voiceExpenseCreated'), {
        action,
      });
      setShowWarning(false);
      reset();
    },
    onError,
  });

  const create = () => {
    createExpense();
  };

  const isLoading =
    isLoadingTranscriptCreate || isLoadingCreate || isFetchingTranscriptResult;
  const isReadyToCreate =
    !isRecording && isTranscriptCreateSuccess && isTranscriptResultSuccess;
  const recordingPercentage = (recordingTime / maxSecs) * 100;

  if (isCollapse) return null;

  return (
    <Content>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        {isRecording && (
          <img
            src="/assets/ai.gif"
            alt="voice animation"
            width={75}
            height={75}
            style={{ display: 'block' }}
          />
        )}
        <Typography color="text.primary" variant="caption" textAlign="center">
          <Writter strings={strings('voiceWelcome')} />
        </Typography>

        {showNoPermission && (
          <Typography color="error" variant="caption" textAlign="center">
            <Writter strings={strings('voiceMicPermission')} />
          </Typography>
        )}

        {showNoMicAvailable && (
          <Typography color="error" variant="caption" textAlign="center">
            <Writter strings={strings('voiceNoMic')} />
          </Typography>
        )}

        {showWarning && (
          <Typography color="error" variant="caption" textAlign="center">
            <Writter
              strings={strings('voiceTimeLimit').replace(
                '%maxSecs',
                maxSecs.toString()
              )}
            />
          </Typography>
        )}

        {isLoading && (
          <Typography variant="body2" textAlign="center">
            <Writter strings={strings('voiceProcessing')} />
          </Typography>
        )}

        {isReadyToCreate && transcript && (
          <Typography variant="body2" textAlign="center">
            <Writter strings={`"${transcript}"`} />
          </Typography>
        )}
        <Stack direction="row" spacing={2} alignItems="center">
          <ActionContainer>
            {isRecording && (
              <CircularProgress
                color={
                  recordingPercentage < 50
                    ? 'success'
                    : recordingPercentage < 75
                    ? 'warning'
                    : 'error'
                }
                variant="determinate"
                value={recordingPercentage}
                style={{
                  width: 56,
                  height: 56,
                }}
                className="progress"
              />
            )}
            <Fab
              className="fab-action"
              color={
                isReadyToCreate
                  ? 'success'
                  : isRecording
                  ? 'default'
                  : 'secondary'
              }
              disabled={isLoading}
              onClick={
                isReadyToCreate
                  ? create
                  : isRecording
                  ? () => {
                      setShowWarning(false);
                      stop();
                    }
                  : start
              }
            >
              {isReadyToCreate ? (
                <Check />
              ) : isRecording ? (
                <Stop color="error" />
              ) : (
                <Mic />
              )}
            </Fab>
          </ActionContainer>
          {isReadyToCreate && (
            <Fab color="error" onClick={cancel} size="small">
              <Cancel />
            </Fab>
          )}
          {mediaRecorder && (
            <LiveAudioVisualizer
              mediaRecorder={mediaRecorder}
              width={56}
              height={28}
              barColor="#f76565"
            />
          )}
        </Stack>
        <Chip label={`${convertStoMs(recordingTime)}`} />
      </Stack>
    </Content>
  );
};
