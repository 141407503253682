import { ProductStrings } from '../es/products';

export const product: ProductStrings = {
  product: 'Product',
  products: 'Products',
  inventoryBulkDeleteWarning: 'Your entire inventory will be deleted.',
  partialDelete1: 'products will be removed from your inventory.',
  partialDelete2: '',
  inventoryReport: 'Inventory report',
  createNewProductTitle: 'Inventory: Create a new product',
  createNewProductHeading: 'Create a new product',
  productCreationSuccess: 'Product created successfully',
  productsCreationSuccess: 'Successfully created products',
  productFormName: 'Product name',
  productFormDescription: 'Product description',
  productFormNewCategory: 'Crate new category',
  productFormShowInStore: 'Show in online store',
  productFormShowInPOS: 'Show in POS',
  productFormHasVariants: 'Product has variants',
  productFormConfigureExtras: 'Configure extras',
  productFormPrice: 'Price',
  productFormCost: 'Cost',
  productFormStock: 'Stock',
  productFormImageUpload: '5 images max',
  productFormCreate: 'Create product',
  productFormErrors: 'Please correct the errors and try again.',
  addExtra: 'Assign extras',
  confirmExtras: 'Configure extras',
  productEditSuccess: 'Product successfully edited',
  stockValidation: 'Stock cannot be less than the existing one',
  variantsValidation: 'You must specify at least 1 variant',
  color: 'Color',
  size: 'Size',
  style: 'Material',
  productDeleteWarning1:
    'This deletion will not be recorded, and will not affect your earnings.',
  productDeleteWarning2:
    'In Details you can find this change as "Damaged Product". In Finance, this type of movement represents an Expense for the business.',
  productDeleteSuccess: 'Product successfully removed',
  productDeleteMessage: 'You are about to delete a product, why is that?',
  productDeleteReason1: 'Damaged product',
  productDeleteReason2: 'I no longer sell it',
  productScanNotFoundTitle: 'Product not found',
  productScanNotFoundDescription: 'This product does not exists',
  productScanNoStockTitle: 'Not enough stock',
  productScanNoStockDescription: 'You do not have enough stock for this sale.',
  latestScan: 'Last Scan',
  stockUpdateSale:
    'Product sales must be recorded through the Point of Sale module or the Accounting module. To ensure proper use in the future, do so through these functions.',
  stockUpdateDamage:
    'In Details you can find this change as "Damaged Product". In Finance this type of movement represents an Expense for the business.',
  stockUpdateError:
    'You will not be able to undo this action, there will be no record.',
  stockUpdateNewCost:
    'The cost of the product will be an average between the units you already had with their respective costs, and the additional units.',
  inventoryUpdate: 'Inventory Update',
  inventoryUpdateMessage:
    'You are about to modify the inventory, what do you want to do?',
  increase: 'Increase',
  decrease: 'Decrease',
  howManyUnits: 'How many units?',
  costChange: 'Is the cost of these units different?',
  byCreationError: 'Due to input error',
  understood: 'Understood',
  inventoryUpdateDismiseTitle: 'Your changes will be discarded',
  inventoryUpdateDismiseMessage: 'No changes will be made',
  continueEditing: 'Continue editing',
  finalStock: 'Final stock',
};
