import { UserAccountStrings } from '../es/userAccount';

export const userAccount: UserAccountStrings = {
  general: 'General',
  cabalPlus: 'Cabal Plus',
  integrations: 'Integrations',
  changePassword: 'Change password',
  yourAccount: 'Your account',
  firstName: 'First Name',
  lastName: 'Last name',
  country: 'Country',
  phone: 'Phone',
  email: 'Email',
  currency: 'Currency',
  industry: 'Industry',
  businessName: 'Business Name',
  changeSuccess: 'Changes saved!',
  oneTimeField: 'This field cannot be edited after saving changes.',
  creatingAccount: 'Creating',
  business: 'Negocio',
  personal: 'Personal',
  creatingBusiness: 'business account',
  creatingPersonal: 'personal finances account',
  accountTypeLogged: 'Logged in as',
  selectYourCountry: 'Select your country',
  employeeCount: 'Number of employees',
  employeeCount1: 'Only me',
  employeeCount2: '0 to 3',
  employeeCount3: '4 to 10',
  employeeCount4: '11 to 25',
  employeeCount5: 'More than 25',
  dollar: 'Dollar',
  currencyFormat: 'Currency format',
  phoneDisclaimer:
    'We use your number to create and send receipts via WhatsApp.',
  finishRegistration: 'Finish registration',
};
