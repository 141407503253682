export const general = {
  voiceWelcome:
    '¡Hola! Bienvenido a Cabal AI, da click en el botón y dí algo como "Crear un gasto de 10 dólares"',
  voiceMicPermission: 'Necesitamos permisos para hacer uso de tu micrófono.',
  voiceNoMic: 'Necesitas un micrófono para hacer uso de esta funcionalidad.',
  voiceTimeLimit:
    'El audio que has grabado supera los %maxSecs segundos, por favor intenta ser mas breve.',
  voiceExpenseCreated: 'Gasto creado con éxito',
  voiceProcessing: 'Procesando...',
  accountUnableToLogout: '¡No se puede cerrar sesión!',
  accountProfile: 'Tu perfil',
  accountLogOut: 'Cerrar Sesión',
  whatsNewTitle: 'Actualizaciones recientes',
  whatsNewNocontent: 'No hay datos disponibles',
  whatsNewError: 'Ocurrió un error cargando la información',
  whatsNewPlublishedOn: 'Publicado el',
  close: 'Cerrar',
  edit: 'Editar',
  loading: 'Cargando',
  search: 'Buscar',
  sort: 'Ordenar',
  filter: 'Filtrar',
  by: 'por',
  name: 'Nombre',
  return: 'Regresar',
  barcode: 'Código de barras',
  none: 'Ninguno',
  priceUp: 'Mayor precio',
  priceDown: 'Menor precio',
  new: 'Nuevo',
  new2: 'Nueva',
  newer: 'Más reciente',
  older: 'Más antiguo',
  price: 'Precio',
  cost: 'Costo',
  add: 'Agregar',
  buy: 'Comprar',
  more: 'más',
  apply: 'Aplicar',
  next: 'Siguiente',
  prev: 'Anterior',
  youHave: 'Tienes',
  order: 'Orden',
  employees: 'Empleados',
  delete: 'Eliminar',
  cancel: 'Cancelar',
  continue: 'Continuar',
  writeConfirmation: 'Para continuar por favor escribe',
  selectAll: 'Seleccionar todos',
  cancelSelection: 'Cancelar selección',
  deleteSelection: 'Eliminar selección',
  bulkSelect: 'Selección multiple',
  outOfStock: 'Stock agotado',
  lowStock: 'Stock bajo',
  createNew: 'Crear nuevo',
  createNew2: 'Crear nueva',
  requestReport: 'Solicitar reporte',
  date1: 'Fecha',
  date2: 'Fechas',
  from: 'Desde',
  until: 'Hasta',
  imageUploaderTitle: 'Selecciona las imágenes',
  imageUploaderDescription:
    'Arrastra los archivos o da click acá para elegir las imágenes',
  imageUploaderRejection:
    'Verifica la cantidad máxima de archivos, he intenta de nuevo.',
  imageUploaderValidation: 'La cantidad máxima de imágenes es 5',
  required: 'Requerido',
  limit: 'Limite',
  image: 'imagen',
  images: 'imágenes',
  max: 'max',
  currentImages: 'Imágenes actuales',
  imageDeleteConfirmation: '¿Estás seguro que deseas eliminar esta imagen?',
  deleteConfirmation: 'Por favor confirma para continuar.',
  saveChanges: 'Guardar cambios',
  save: 'Guardar',
  confirm: 'Confirmar',
  important: 'Importante',
  regenerate: 'Regenerar',
  regenerateWarning:
    'Los servicios que tengas integrados dejarán de funcionar.Luego de regenerar el API Key actualiza todas las integraciones que hayas hecho con el nuevo código para evitar inconvenientes.',
  apiKey: 'API Key',
  noApiKey: 'No cuentas con una API Key activa.',
  copy: 'Copiar',
  copied: 'Copiado!',
};
export type GeneralStrings = typeof general;
