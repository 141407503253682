import { NavigationStrings } from '../es/navigation';

export const navigation: NavigationStrings = {
  myModules: 'Modules',
  myPOS: 'Point of Sale (POS)',
  start: 'Start',
  posSale: 'Sales',
  sales: 'Sales',
  sale: 'Sale',
  income: 'Income',
  orders: 'Orders',
  clients: 'Clients',
  contacts: 'Contacts',
  products: 'Products',
  all: 'All',
  dashboard: 'Dashboard',
  all2: 'All',
  categories: 'Categories',
  category: 'Category',
  analytics: 'Analytics',
  posAnalytics: 'POS Analytics',
  myAccounting: 'Accounting',
  expenses: 'Expenses',
  expense: 'Expense',
  debts: 'Debts',
  debt: 'Debt',
  transactions: 'Transactions',
  myStore: 'My Store',
  requests: 'Orders',
  offers: 'Offers',
  myInventory: 'Inventory',
  financialAssistant: 'Financial Assistant',
  organizations: 'Organizations',
  myOrganizations: 'My organizations',
  rolesPermissions: 'Roles and permissions',
  tutorials: 'Tutorials',
  services: 'Services',
  extras: 'Extras',
  financialStatements: 'Financial Statements',
  quotations: 'Quotations',
  personalization: 'Personalization',
  importData: 'Import data',
};
