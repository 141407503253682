import { faShop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { QueryTypes } from 'src/@types/queryTypes';
import {
  V1WebTransactionsCountGet200Response,
  WebTransactionsApi,
  WebTransactionsApiV1WebTransactionsGetRequest,
} from 'src/api-client';
import { useStrings } from 'src/hooks/useStrings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import palette from 'src/theme/palette';

type Props = {
  processStatus: WebTransactionsApiV1WebTransactionsGetRequest['processStatus'];
  refetchInterval?: number;
};

export const WebOrderCounter = ({ processStatus, refetchInterval }: Props) => {
  const { strings } = useStrings();
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const { data } = useQuery(
    [`${QueryTypes.WebTransactions}-${processStatus}-counter`],
    async () => {
      const response = await WebTransactionsApi.v1WebTransactionsCountGet();

      return response.data;
    },
    {
      refetchInterval: refetchInterval ? refetchInterval : false,
    }
  );

  const handleData = (
    data: V1WebTransactionsCountGet200Response | undefined
  ) => {
    setTotal(data?.pending_orders ?? 0);
  };

  useEffect(() => {
    handleData(data);
  }, [data]);

  return (
    <Tooltip
      title={`${strings('youHave')} ${total} ${
        total > 1 || total === 0 ? strings('orders') : strings('order')
      }`}
    >
      <Badge badgeContent={total} color="secondary">
        <IconButton onClick={() => navigate(PATH_DASHBOARD.web.root())}>
          <FontAwesomeIcon
            size="xs"
            icon={faShop}
            color={total > 0 ? palette.light.primary.main : undefined}
          />
        </IconButton>
      </Badge>
    </Tooltip>
  );
};
