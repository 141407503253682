import { general } from './general';
import { navigation } from './navigation';
import { product } from './products';
import { subscription } from './subscription';
import { userAccount } from './userAccount';

const es = {
  ...navigation,
  ...general,
  ...product,
  ...userAccount,
  ...subscription,
};

export type TranslationKeys = typeof es;

export default es;
